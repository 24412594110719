import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/app/[...uids]/components/SocialWallFlockler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScheduleSearchTable"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/app/schedule-search/ScheduleSearchTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HalfPageAd"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/Ad/HalfPageAd.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/Ad/LeaderBoardAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RectangleAd"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/Ad/RectangleAd.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/layout/ContentHeader.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/router/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/table/Table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/components/ui-other/basic/Button.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dhb-handball-net/dhb-handball-net/web/node_modules/next/dist/client/link.js");
